import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { Common } from 'app/model/entity/common';
import { CommonTable } from 'app/model/entity/commonTable';
import { AnnouncementManagerService } from 'app/service/announcement/announcement-manager.service';
import { CommonTableService } from 'app/service/common-table.service';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import * as fileSaver from 'file-saver';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
@Component({
  selector: 'dialog-glossary',
  templateUrl: './dialog-glossary.component.html',
  styleUrls: ['./dialog-glossary.component.scss']
})
export class DialogGlossaryComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<DialogGlossaryComponent>,
    private announcementManagerService: AnnouncementManagerService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private commonTableService: CommonTableService,
    private commonService: CommonService
  ) {
    this.commonObject = this.commonService.getCommonObject();
    this.translateService.onLangChange.subscribe(() => {
      this.languageKey = this.commonService.getCommonObject().setting?.language;
    });
  }

  /**
   * fileName
   */
  fileName: string;

  /**
   * glossary Object
   */
  glossaryObject: any;

  /**
   * commonObject
   */
  commonObject: Common;

  /**
   * glossary
   */
  glossary: string;

  /**
   * language key
   */
  public languageKey: string;

  keyCommonTable: string;
  ngOnInit(): void {
    this.languageKey = this.commonObject.setting.language;
    switch (this.data.screen) {
      case Constant.ANNOUNCEMENT_MANAGER_URL:
        this.keyCommonTable = Constant.KEY_GLOSSARY;
        break;
      case Constant.TICKET_EDITOR_COMPONENT_URL:
        this.keyCommonTable = Constant.KEY_GLOSSARY_TICKET;
        break;
      case Constant.PUSH_NOTIFICATION_COMPONENT_URL:
        this.keyCommonTable = Constant.KEY_GLOSSARY_NOTIFICATION;
        break;
    }
    this.commonTableService.getValueCommonTableByKey(this.keyCommonTable).subscribe(data => {
      if (data) {
        this.glossaryObject = JSON.parse(data.value);
        this.fileName = Object.keys(this.glossaryObject)[0];
      }
    });
  }

  /**
   * import
   */
  import() {
    if (this.fileName) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('dialog-glossary.confirm-import'),
            button1: this.translateService.instant('dialog-glossary.yes'),
            button2: this.translateService.instant('dialog-glossary.no')
          }
        },
        result => {
          if (!result) {
            return;
          }
          let element = document.getElementById('importedFileGlossary') as HTMLInputElement;
          element.setAttribute('accept', '.xlsx');
          element.click();
        }
      );
    } else {
      let element = document.getElementById('importedFileGlossary') as HTMLInputElement;
      element.setAttribute('accept', '.xlsx');
      element.click();
    }
  }

  /**
   * upload File
   * @param event
   * @returns
   */
  uploadFile(event: any) {
    let selectedFiles: File[] = event.target.files;
    if (selectedFiles.length > 1) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-glossary.multi-file')
        }
      });
      return;
    }
    const file = selectedFiles[0];
    const typeFiles = ['xlsx'];
    let typeName = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
    if (!typeFiles.includes(typeName)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-glossary.not-support')
        }
      });
      return;
    }
    if (file.size > Constant.LIMIT_SIZE_FILE_GLOSSARY) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-glossary.max-size')
        }
      });
      return;
    }
    const XLSX = require('xlsx');

    const fileReader = new FileReader();
    fileReader.onload = (e: any) => {
      const glossary = e.target.result;
      const workbook = XLSX.read(glossary, { type: 'binary' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const translateCodes = Constant.LANGUAGES_SETTING.map(language => language['translation_language_code']);
      const indexFirstRow = rows.findIndex(row => row.indexOf(Constant.START_CODE) === 0);

      const indexKeyRow = rows.findIndex((row, index) => index > indexFirstRow && row.indexOf(Constant.START_CODE) === 0);
      const indexTextRow = rows.findIndex((row, index) => index > indexKeyRow && row.indexOf(Constant.START_CODE) === 0);
      const glossaryName = rows[indexFirstRow][2];
      if (!glossaryName) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-glossary.error-empty')
          }
        });
        return;
      } else if (glossaryName.length > 30) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-glossary.error-maxlength')
          }
        });
        return;
      }
      if (indexFirstRow != 0 || indexKeyRow != 5 || !rows[indexFirstRow].includes('名称') || indexTextRow == -1 || !rows[indexTextRow]) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-glossary.error-format')
          }
        });
        return;
      }
      if (this.validateContent(rows, indexKeyRow)) {
        return;
      }
      if (
        !rows[indexKeyRow].slice(1).length ||
        rows[indexKeyRow].slice(1).some(code => !translateCodes.includes(code.trim())) ||
        this.languageEmpty(rows[indexKeyRow])
      ) {
        if (rows[indexKeyRow])
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('dialog-glossary.language-invalid')
            }
          });
        return;
      }
      this.convertFileToImport(rows, rows[indexKeyRow], glossaryName);
    };
    fileReader.readAsBinaryString(file);

    // reset input file value
    let element = document.getElementById('importedFileGlossary') as HTMLInputElement;
    element.value = null;
  }

  /**
   * languageEmpty
   * @param value
   * @returns
   */
  private languageEmpty(value: any): boolean {
    let size = value.length;
    for (let i = 0; i < size; i++) {
      if (!value[i]) {
        return true;
      }
    }
    return false;
  }

  /**
   * validate Content
   * @param glossary
   * @returns
   */
  validateContent(rows: string[], indexKeyRow: number): boolean {
    const rowLanguage = rows[indexKeyRow];
    if (rowLanguage.slice(1).length > 10) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-glossary.max-target-code')
        }
      });
      return true;
    }
    const lengthLanguage = rowLanguage;
    if (indexKeyRow != 5 || !rowLanguage || rowLanguage.includes(',,') || !lengthLanguage[lengthLanguage.length - 1]) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-glossary.language-invalid')
        }
      });
      return true;
    }
    let flag = -1;
    let textsErrorMaxSize = new Array<string>();
    let textsErrorSpecial = new Array<string>();
    let textsErrorDuplicate = new Array<string>();
    const regex = /¥|\/|"|&|\'|<|>/;
    for (let i = indexKeyRow; i < rows.length; i++) {
      if (rows[i].indexOf(Constant.START_CODE) == -1) {
        continue;
      }
      const listText = rows[i].slice(1);
      for (let text of listText) {
        if (Buffer.byteLength(text, 'utf8') > 100) {
          textsErrorMaxSize.push(Helper.formatString(this.translateService.instant('dialog-glossary.row-error'), (i + 1).toString()));
          flag = 1;
        } else if (flag != 1 && regex.test(text)) {
          textsErrorSpecial.push(Helper.formatString(this.translateService.instant('dialog-glossary.row-error'), (i + 1).toString()));
          flag = 2;
        }
      }
    }
    if (flag == 1) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-glossary.title-max-size'),
          texts: textsErrorMaxSize
        }
      });
      return true;
    }
    if (flag == 2) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          textHasLineBreaks: this.translateService.instant('dialog-glossary.title-special-character'),
          texts: textsErrorSpecial
        }
      });
      return true;
    }
    let listTextTranslate = new Array<string>();
    for (let i = indexKeyRow + 1; i < rows.length; i++) {
      if (rows[i].indexOf(Constant.START_CODE) == -1) {
        continue;
      }
      const listText = rows[i].slice(1);
      listTextTranslate.push(listText[0]);
      if (listTextTranslate.length > 256) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-glossary.max-length-character')
          }
        });
        return true;
      }
    }
    for (let i = 0; i < listTextTranslate.length; i++) {
      const duplicateIndex = listTextTranslate.indexOf(listTextTranslate[i], i + 1);
      if (flag != 1 && flag != 2 && duplicateIndex > -1) {
        textsErrorDuplicate.push(
          Helper.formatString(this.translateService.instant('dialog-glossary.row-error'), (i + indexKeyRow + 2).toString())
        );
        textsErrorDuplicate.push(
          Helper.formatString(this.translateService.instant('dialog-glossary.row-error'), (duplicateIndex + indexKeyRow + 2).toString())
        );
        flag = 3;
        break;
      }
    }
    if (flag == 3) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-glossary.duplicate-text'),
          texts: textsErrorDuplicate
        }
      });
      return true;
    }
    return false;
  }

  /**
   * export
   */
  export() {
    let payload;
    const moment = require('moment');
    const currentDay = Helper.getCurrentDateByTimeZone(this.commonObject);
    let name = '';
    switch (this.data.screen) {
      case Constant.ANNOUNCEMENT_MANAGER_URL:
        name = this.translateService.instant('dialog-glossary.key-name-announcement');
        break;
      case Constant.TICKET_EDITOR_COMPONENT_URL:
        name = this.translateService.instant('dialog-glossary.key-name-ticket');
        break;
      case Constant.PUSH_NOTIFICATION_COMPONENT_URL:
        name = this.translateService.instant('dialog-glossary.key-name-notification');
        break;
    }
    const key = Helper.formatString(name, moment(currentDay, Constant.FORMAT_DATE_TIME1).format('YYYYMMDDHHmmss'));
    this.commonTableService.getValueCommonTableByKey(this.keyCommonTable).subscribe(data => {
      if (data && JSON.parse(data.value) != '') {
        this.glossaryObject = JSON.parse(data.value);
        this.fileName = Object.keys(this.glossaryObject)[0];
        let content = this.glossaryObject[`${this.fileName}`];
        let rows = content.includes('\r') ? content.split('\r\n') : content.split('\n');
        const indexFirstRow = rows.findIndex(row => row.indexOf(Constant.START_CODE) == 0);
        let textDates = rows[indexFirstRow + 1].split(',');
        textDates[2] = moment(currentDay, Constant.FORMAT_DATE_TIME1).format('YYYY/MM/DD');
        rows[indexFirstRow + 1] = textDates.join(',');
        let textTimes = rows[indexFirstRow + 2].split(',');
        textTimes[2] = moment(currentDay, Constant.FORMAT_DATE_TIME1).format('hh:mm:ss');
        rows[indexFirstRow + 2] = textTimes.join(',');
        content = rows.join('\r\n');
        this.glossaryObject[`${this.fileName}`] = content;
        payload = JSON.stringify(this.glossaryObject);
      } else {
        const dateByTimeZone = moment(currentDay, Constant.FORMAT_DATE_TIME1).format('YYYY/MM/DD');
        const timeByTimeZone = moment(currentDay, Constant.FORMAT_DATE_TIME1).format('hh:mm:ss');
        let languageName: any = [];
        let languageCode;
        if (this.commonObject.setting.languagesSetting) {
          const languagesSetting = this.commonObject.setting?.languagesSetting.includes('"')
            ? this.commonObject.setting?.languagesSetting.slice(1, -1).split(',')
            : this.commonObject.setting?.languagesSetting.split(',');
          languageCode = languagesSetting.join(',');
          for (let language of languagesSetting) {
            const index = Constant.LANGUAGES_SETTING.findIndex(e => e.translation_language_code == language);
            if (index != -1) {
              languageName.push(Constant.LANGUAGES_SETTING[index].language_name_ja);
            }
          }
          let keyCompare = this.languageKey == Constant.JP_LANGUAGE ? 'ja' : 'en';
          if (!languageName.length) {
            languageName = this.languageKey == Constant.JP_LANGUAGE ? '日本語' : '英語';
            languageCode = this.languageKey == Constant.JP_LANGUAGE ? 'ja' : 'en';
          } else if (!languageCode.includes(keyCompare)) {
            languageCode = `${keyCompare},${languageCode}`;
            languageName.unshift(keyCompare == 'ja' ? '日本語' : '英語');
          }
        } else {
          languageName = this.languageKey == Constant.JP_LANGUAGE ? '日本語' : '英語';
          languageCode = this.languageKey == Constant.JP_LANGUAGE ? 'ja' : 'en';
        }
        const formatNameDate = Helper.formatString(
          Constant.FORMAT_EXPORT_GLOSSARY,
          key,
          dateByTimeZone,
          timeByTimeZone,
          languageName,
          languageCode
        );
        const object: { [key: string]: any } = {
          [key]: formatNameDate
        };
        payload = JSON.stringify(object);
      }
      this.announcementManagerService.exportGlossary(payload).subscribe(response => {
        const file = new File([response.body], key, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        fileSaver.saveAs(file);
      });
    });
  }

  /**
   * convertFileToImport
   * @param rows
   * @param rowKey
   * @param fileName
   * @returns
   */
  convertFileToImport(rows: string[][], rowKey: string[], fileName: string): void {
    const indexRowLanguageKey = rows.indexOf(rowKey);
    const xlsxRows = [];

    for (let i = 0; i < rows.length; i++) {
      if (i <= indexRowLanguageKey) {
        const row = rows[i].join(',');
        xlsxRows.push(row);
      } else if (rows[i].includes(Constant.START_CODE)) {
        const countLanguageKey = rows[indexRowLanguageKey].length - 1;
        let row = rows[i].join(',');
        const countValueRow = row.split(',').length - 1;
        if (countValueRow < countLanguageKey) {
          for (let i = countValueRow; i < countLanguageKey; i++) {
            row = `${row},`;
          }
        }
        xlsxRows.push(row);
      }
    }
    const glossary = xlsxRows.join('\n');
    const glossaryImport = xlsxRows.slice(indexRowLanguageKey).join('\n');
    const content = glossaryImport.replace(/<\$\$>,/gm, '');
    const blob = new Blob([content], { type: 'text/xlsx' });
    const xlsxFile = new File([blob], `${fileName}.xlsx`, { type: 'text/xlsx' });
    this.announcementManagerService.importGlossaryFile(xlsxFile, this.data.screen).subscribe(
      () => {
        this.fileName = `${fileName}`;
        const object: { [key: string]: any } = {
          [fileName]: glossary
        };
        let commonTableSave = new CommonTable();
        commonTableSave.key = this.keyCommonTable;
        commonTableSave.value = JSON.stringify(object);
        this.commonTableService.save(commonTableSave).subscribe();
      },
      error => {
        if (error.error) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('dialog-glossary.error-save')
            }
          });
          return;
        }
      }
    );
  }

  /**
   * delete
   */
  delete(): void {
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          title: this.translateService.instant('dialog-confirm.title'),
          text: this.translateService.instant('dialog-confirm.timetable-operation-manager.confirm-delete-glosary'),
          button1: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-1'),
          button2: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-2')
        }
      },
      result => {
        if (!result) {
          return;
        }
        this.announcementManagerService.deleteGlossary(this.data.screen).subscribe(() => {
          this.glossaryObject = undefined;
          this.fileName = undefined;
          let commonTableSave = new CommonTable();
          commonTableSave.key = this.keyCommonTable;
          commonTableSave.value = JSON.stringify('');
          this.commonTableService.save(commonTableSave).subscribe();
        });
      }
    );
  }

  close(): void {
    this.dialogRef.close(this.fileName);
  }
}

export interface DialogData {
  screen: string;
}
